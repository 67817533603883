import { CDN_LINK } from "@globalConfig";
import { getNumberWithOutPlus } from "../../../../utils/GlobalJavascriptFunction";
import {useTranslation} from "@i18n";

import stl from "./AnyQust.module.css";
import {
  onPushDataLayer,
  onPushDataLayerNew
} from "../../../../utils/events";
import { trackEvents } from "../../../../common/events/events";
import FaceBookAQSVG from "./anyQuestSvgs/FaceBookAQSVG";
import TwitterAQSVG from "./anyQuestSvgs/TwitterAQSVG";
import InstagramAQSVG from "./anyQuestSvgs/InstagramAQSVG";
import SnapchatAQSVG from "./anyQuestSvgs/SnapchatAQSVG";
import YoutubeQASVG from "./anyQuestSvgs/YoutubeQASVG";

const AnyQust = ({ contact, isContactUs, hasSocilaLinks }) => {
  const { t } = useTranslation();
  return (
    <>
      {isContactUs && (
        <div className={[stl.mobileCallUs, "m-show"].join(" ")}>
          <a
            className={["blueBtn"].join(" ")}
            href={`tel:${
              contact &&
              contact.phone &&
              getNumberWithOutPlus(contact.phone.number)
            }`}
            onClick={() => {
              onPushDataLayer("event", "communication", "contact_us", "call");
            }}>
            {contact && contact.phone && (
              <>
                <img src={CDN_LINK + "/assets/images/Call.svg"} alt="" />
                <span>{t("اتصل", { ns: "web_common" })}</span>
              </>
            )}
          </a>

          <a
            className="greenBtn"
            href={`https://wa.me/+966${
              contact &&
              contact.whatsapp &&
              getNumberWithOutPlus(contact.whatsapp.number)
            }`}
            target="_blank"
            onClick={() => {
              // onlineContactEvent("online_contact", "Homepage", null);
              trackEvents("online_contact", {
                post_id: null,
                contact_type: "online_contact",
                contact_location: "Homepage",
              });
              onPushDataLayer(
                "event",
                "communication",
                "contact_us",
                "whatsapp"
              );
            }}>
            <img
              src={CDN_LINK + "/assets/images/wpImg.svg"}
              width={"24px"}
              height={"24px"}
              alt=""
            />
            <span>{t("واتساب", { ns: "web_home" })}</span>
          </a>
        </div>
      )}
      <div
        className={[
          "anyQustContainer",
          isContactUs ? "m-hide" : undefined,
        ].join(" ")}>
        <div className="container">
          <div className={"callHelpContainer"}>
            <span style={{ fontSize: "65px" }} className="icon-telephoneRed">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>

            <strong>{t("عندك أي استفسار؟", { ns: "web_home" })}</strong>
            <p>
              {t("نخدمك طيلة أيام الاسبوع خلال اوقات العمل التالية:", {
                ns: "web_home",
              })}
              <br />
              {t("من السبت حتى الخميس من الساعة 9 صباحاً حتى 11", {
                ns: "web_home",
              })}
              <br />
              {t("مساءً, الجمعة من 1 مساءً الى 11 مساءً", {
                ns: "web_home",
              })}
            </p>
            <span>
              <a
                className={[
                  // this.props.isContactUs ? stl.isContactUs : undefined,
                ].join(" ")}
                href={`tel:${
                  contact &&
                  contact.phone &&
                  getNumberWithOutPlus(contact.phone.number)
                }`}
                onClick={() => {
                  // onlineContactEvent("call", "Homepage", null);
                  trackEvents("online_contact", {
                    post_id: null,
                    contact_type: "call",
                    contact_location: "Homepage",
                  });
                  onPushDataLayer(
                    "event",
                    "communication",
                    "contact_us",
                    "call"
                  );
                  onPushDataLayerNew("syarah_events", "contact", "CSRContact");
                }}>
                {contact && contact.phone && (
                  <>{getNumberWithOutPlus(contact.phone.number)}</>
                )}
              </a>
            </span>
            <a
              href={`https://wa.me/+966${
                contact &&
                contact.whatsapp ?
                getNumberWithOutPlus(contact.whatsapp.number):"920005379"
              }`}
              target="_blank"
              onClick={() => {
                //onlineContactEvent("whatsapp", "Homepage", null);
                trackEvents("online_contact", {
                  post_id: null,
                  contact_type: "whatsapp",
                  contact_location: "Homepage",
                });
                onPushDataLayer(
                  "event",
                  "communication",
                  "contact_us",
                  "whatsapp"
                );
                onPushDataLayerNew("syarah_events", "contact", "CSRContact");
              }}>
              <span className="icon-wpImg" style={{ fontSize: "24px" }}>
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
              </span>

              <span>{t("تواصل عبر واتساب", { ns: "web_home" })}</span>
            </a>
          </div>
        </div>
      </div>

      {hasSocilaLinks && (
        <div className={["container", stl.contMaxWidth].join(" ")}>
          <div className={[stl.socialLinks].join(" ")}>
            <h2>
              {" "}
              {t("تابعنا لتصلك آخر عروض السيارات", {
                ns: "web_home",
              })}
            </h2>
            <div>
              <a href="https://www.facebook.com/syarah.KSA">
                <FaceBookAQSVG />
              </a>
              <a href="https://twitter.com/Syarah_ksa">
                <TwitterAQSVG />
              </a>
              <a href="https://www.instagram.com/Syarah_ksa/">
                <InstagramAQSVG />
              </a>
              <a href="https://www.snapchat.com/add/syarah_ksa">
                <SnapchatAQSVG />
              </a>
              <a href="https://www.youtube.com/channel/UCAceZ20JD3qef5OQSVc95Bw">
                <YoutubeQASVG />
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AnyQust;
